@media screen and (max-width:1679px) {
  .uk-container {
    max-width: 960px;
  }

  .getcard .header .uk-slider-items {
    min-height: 330px!important;
  }

  .home .faq:after {
    content: '';
    top: -20%;
    left: 50%;
    margin-left: 22%;
  }

  .navbar-nav>li>a {
    font-size: 14px;
    line-height: 17px;
  }

  .logo>span {
    bottom: -16px;
    left: 42px;
    font-size: 9px;
  }

  .main-menu .navbar-nav {
    margin-left: 33px;
  }

  .uk-offcanvas-container .main-menu .navbar {
    opacity: 0;
  }

  .header .block .uk-container span {
    font-size: 42px;
    line-height: 50px;
  }

  .home .faq:after,
  .home:after {
    width: 903px;
    height: 693px;
    background-size: contain;
  }

  .login-container {
    text-align: right;
  }

  .offcanvas-container .login-container>a {
    display: block;
    width: 100%;
    margin: 0;
    background: linear-gradient(52deg,#feb400 21.32%,#ff6001 93.91%)!important;
    border: 0;
  }

  .main-menu:not(.footer) {
    margin-top: 23px;
  }

  .main-menu:not(.footer) .logo {
    margin-left: -8px;
  }

  .main-menu:not(.footer) .logo img {
    max-width: 197px;
    margin-top: 5px;
    margin-left: 7px;
  }

  .main-menu:not(.footer) .logo span {
    bottom: -17px;
    left: 50px;
    font-size: 9px;
  }

  .main-menu:not(.footer) .login-container {
    text-align: left;
  }

  .header {
    margin-top: 21px;
  }

  .header .uk-slider-items {
    min-height: 410px!important;
  }

  .header .text-block {
    left: calc(50% - 960px/2);
    right: calc(50% - 960px/2);
    padding-left: 90px;
    padding-right: 90px;
  }

  .header .text-block span {
    min-width: 80%;
    font-size: 36px;
    line-height: 46px;
  }

  .header .text-block p {
    color: #fff;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  }


  .header .text-block .primary {
    margin-top: 50px;
  }

  .header .uk-slidenav-previous {
    left: calc(50% - 960px/2 - 25px);
  }

  .header .uk-slidenav-next {
    right: calc(50% - 960px/2 - 25px);
  }

  #main:not(.program) .uk-slidenav-previous {
    left: -25px;
  }

  #main:not(.program) .uk-slidenav-next {
    right: -25px;
  }

  .how-get {
    margin-top: 9px;
  }

  .how-get .title {
    margin-bottom: 17px;
  }

  .how-get .text-align {
    text-align: left;
  }

  .stocks .text-block span.invert {
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 30px;
  }

  .stocks .uk-slidenav-previous {
    left: -20px;
  }

  .stocks .uk-slidenav-next {
    right: -20px;
  }

  .title {
    font-size: 32px;
    line-height: 38px;
  }

  .contact-form {
    padding: 30px 0;
  }

  .contact-form .uk-container-small {
    margin-bottom: 0;
  }

  .button.primary,
  a.button.primary {
    padding-left: 35px;
    padding-right: 35px;
  }

  .advantages {
    margin-top: 51px;
  }

  .advantages .title-block>div+p {
    margin-top: 15px;
  }

  .footer .navbar-nav {
    margin-left: 0;
  }

  .footer .navbar-nav>li>a {
    text-align: center;
  }

  .footer .uk-grid>div:first-child {
    text-align: center;
  }

  .get-card-content .uk-grid:nth-of-type(1)>div:last-child p:first-child {
    margin-top: 111px;
  }

  .get-card-content .uk-grid:nth-of-type(1)>div:last-child p {
    padding-right: 0;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child a,
  .get-card-content .uk-grid:nth-of-type(2)>div:last-child p {
    margin-left: calc(35% + 15px);
  }

  .get-card-content .uk-grid:nth-of-type(2) {
    margin-top: 63px;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div img {
    margin-left: 8px;
  }

  .get-card-content .uk-grid:nth-of-type(3) {
    margin-top: 97px;
  }

  .get-card-content .title-card.icon-first:after {
    width: 422px;
    height: 223px;
    background-size: contain;
    left: -132%;
    top: 77px;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card.icon-second:after {
    background-size: contain;
    width: 427px;
    height: 223px;
    left: -24%;
    bottom: -314px;
  }

  .get-card-content .uk-grid:nth-of-type(3)>div:last-child .title-card.icon-third::before {
    width: 64px;
    height: 64px;
    background-size: contain;
    left: -102px;
    top: -13px;
  }

  .uk-container:not(.footer) .navbar-nav>li:nth-of-type(1)>a {
    width: 112px;
  }

  .uk-container:not(.footer) .navbar-nav>li:nth-of-type(2)>a {
    width: 121px;
    //padding-right: 5px;
  }

  .uk-container:not(.footer) .navbar-nav>li:nth-of-type(3)>a {
    width: 153px;
    //padding-right: 5px;
  }

  .uk-container:not(.footer) .navbar-nav>li:nth-of-type(4)>a {
    width: 66px;
  }

  .uk-container:not(.footer) .navbar-nav>li:nth-of-type(5)>a {
    width: 118px;
    //padding-right: 5px;
  }

  .main-menu .uk-grid>div:last-child {
    padding-left: 7px;
  }

  .main-menu.footer .uk-grid>div:last-child {
    padding-left: 44px;
  }

  .get-card-content {
    margin-top: 84px;
  }

  .get-card-content a{
    position: relative;
    z-index: 2;
  }

  .getcard .footer {
    margin-top: 163px;
  }

  .footer .navbar-nav li:nth-of-type(1) a {
    width: 138px;
  }

  .footer .navbar-nav li:nth-of-type(2) a {
    width: 139px;
  }

  .footer .navbar-nav li:nth-of-type(3) a {
    width: 181px;
  }

  .footer .navbar-nav li:nth-of-type(4) a {
    width: auto;
  }

  .footer .navbar-nav li:nth-of-type(5) a {
    width: 176px;
  }
}

@media screen and (max-width:1270px) {
  .uk-container {
    max-width: 728px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .breadcrumb {
    margin-top: 25px;
  }

  .get-card-content .title-card {
    font-size: 18px;
    line-height: 22px;
  }

  .getcard {
    margin-top: 8px;
  }

  .getcard .header {
    margin-top: 30px;
  }

  .getcard .header .uk-slider-items {
    min-height: 280px!important;
  }

  .get-card-content {
    margin-top: 84px;
  }

  .get-card-content img {
    width: 274px;
  }

  .how-get {
    position: relative;
  }

  .how-get:before {
    content: '';
    background: url(../images/bt-triangle.svg) center no-repeat;
    background-size: contain;
    width: 717px;
    height: 551px;
    position: absolute;
    top: -16%;
    right: 50%;
    margin-right: 19%;
    z-index: -1;
    -webkit-transform: rotate(72deg);
    -ms-transform: rotate(72deg);
    transform: rotate(72deg);
  }

  .stocks:before {
    content: none!important;
  }

  .footer .uk-grid>div:first-child {
    text-align: center;
  }

  .footer .navbar-nav {
    margin-left: 0;
  }

  .stocks .uk-slider-items {
    min-height: 320px;
  }

  .contact {
    margin-bottom: 35px;
  }

  .how-get .get-card {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-direction: row;
  }

  .how-get .get-card .title-card,
  .how-get .get-card p+p {
    text-align: left;
    padding: 0;
  }

  .how-get .get-card>div {
    padding-left: 35px;
  }

  .how-get .get-card img {
    max-width: 313px;
  }

  .login-container .button {
    height: 36px;
    line-height: 36px;
    min-height: 36px;
  }

  .header .text-block {
    left: calc(50% - 728px/2);
    right: calc(50% - 728px/2);
    padding-left: 90px;
    padding-right: 90px;
  }

  .header .text-block span {
    min-width: 80%;
    font-size: 38px;
    line-height: 46px;
  }

  .header .text-block .primary {
    margin-top: 50px;
  }

  .header .uk-slidenav-previous {
    left: calc(50% - 728px/2 - 25px);
  }

  .header .uk-slidenav-next {
    right: calc(50% - 728px/2 - 25px);
  }

  .get-card-content .title-card.icon-first:after {
    background: url(../images/icons/linetodown.png) center no-repeat;
    width: 12px;
    height: 200px;
    background-size: contain;
    left: -51px;
    top: 51px;
  }

  .get-card-content .uk-grid:nth-of-type(3)>div:last-child p {
    padding-right: 0;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card.icon-second:after {
    background: url(../images/icons/linetodown.png) center no-repeat;
    width: 12px;
    height: 243px;
    background-size: contain;
    left: -51px;
    top: 51px;
  }

  .get-card-content .title-card.icon-first:before {
    width: 44px;
    height: 44px;
    background-size: contain;
    left: -68px;
    top: -8px;
  }

  .get-card-content .uk-grid:nth-of-type(1)>div:last-child p:first-child {
    margin-top: 30px;
  }

  .get-card-content .uk-grid>div:last-child a,
  .get-card-content .uk-grid>div:last-child p {
    margin-left: 0;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child a,
  .get-card-content .uk-grid:nth-of-type(2)>div:last-child p {
    margin-left: 0;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card {
    margin-top: 62px;
    margin-bottom: 20px;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card+p {
    font-size: 16px;
    line-height: 24px;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card.icon-second:before {
    width: 42px;
    height: 42px;
    background-size: contain;
    left: -67px;
    top: -4px;
  }

  .get-card-content .uk-grid:nth-of-type(3)>div:last-child .title-card.icon-third::before {
    width: 43px;
    height: 44px;
    left: -69px;
    top: -8px;
  }

  .get-card-content .uk-grid:nth-of-type(3) {
    margin-top: 74px;
  }

  .get-card-content .uk-grid:nth-of-type(3)>div:last-child p:first-child {
    margin-top: 34px;
  }

  .getcard .footer {
    margin-top: 97px!important;
  }

  .getcard .footer .uk-grid>div:last-child {
    margin-top: 45px;
  }

  .getcard .copyright {
    margin-top: 43px;
  }

  .main-menu:not(.footer) .login-container {
    text-align: right;
  }

  .main-menu:not(.footer) .logo img {
    width: 227px;
    max-width: 227px;
  }

  .main-menu:not(.footer) {
    margin-top: 2px;
  }

  .main-menu.footer .uk-grid>div:last-child {
    padding-left: 30px;
  }

  .footer .navbar-nav li a {
    width: inherit!important;
  }

  .button-cabinet li>a {
    font-size: 16px;
    line-height: 19px;
    padding: 13px;
  }

  .button-cabinet li>a>svg {
    margin-right: 13px;
  }

  .home .header .text-block {
    //left: calc(50% - 768px/2 + 15px);
    right: calc(50% - 768px/2 + 15px);
  }

  .home .header .uk-slidenav-next {
    right: calc(50% - 768px/2 - 25px);
  }

  .home .header .uk-slidenav-previous {
    left: calc(50% - 768px/2 - 25px);
  }
}


@media screen and (max-width:1000px) {
  .home .header .text-block {
    left: calc(50% - 768px/2 + 15px);
  }
}

@media screen and (max-width:767px) {
  .uk-container {
    max-width: 100%;
  }

  .home:after,
  .home:before {
    content: none;
  }

  .button.bordered,
  .button.default,
  .button.primary,
  a.button.bordered,
  a.button.default,
  a.button.primary {
    height: 42px;
    line-height: 42px;
    min-height: 42px;

  }

  .button.default,
  a.button.default {
    min-width: 230px;
  }

  .header .uk-slider .uk-slidenav,
  .stocks .uk-slidenav {
    display: none;
  }

  .stocks .uk-slider-items {
    min-height: 320px;
  }

  .stocks .text-block {
    padding: 0 20px;
    -webkit-box-align: center;
    align-items: center;
  }

  .stocks .text-block span.invert {
    min-width: 100%;
    text-align: center;
    font-size: 24px;
    line-height: 29px;
  }

  .stocks .text-block p {
    text-align: center;
    font-size: 14px;
    line-height: 17px;
  }

  .slider-other .slidenav-container>a:last-child {
    margin-left: 25px;
  }

  .slider-other .slidenav-container>a:first-child {
    margin-right: 25px;
  }

  .contact-form h4 {
    font-size: 22px;
    line-height: 26px;
    position: relative;
    z-index: 2;
    padding-left: 15px;
    padding-right: 15px;
  }

  .main-menu .logo img {
    max-width: 194px;
  }

  .main-menu .logo>span {
    font-size: 10px;
  }

  .header .text-block {
    -webkit-box-align: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    left: calc(50% - 480px/2);
    right: calc(50% - 480px/2);
  }

  .header .text-block span {
    min-width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 34px;
  }

  .title {
    font-size: 24px;
    line-height: 29px;
  }

  .how-get .get-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .how-get .get-card .title-card,
  .how-get .get-card p+p,
  .slider-other {
    text-align: center;
  }

  .how-get .get-card>div {
    padding-left: 0;
  }

  .how-get .text-align .button {
    display: block;
    text-align: center;
  }

  .how-get .button {
    margin-bottom: 25px;
  }

  .faq .uk-accordion {
    margin-left: 0;
    margin-right: 0;
  }

  .faq .uk-accordion .uk-accordion-title {
    padding-right: 20%;
  }

  .contact-form .uk-container-small {
    z-index: 2;
    position: relative;
    padding: 0 15px;
  }

  .slider-other li div:first-child {
    -webkit-box-pack: center;
    justify-content: center;
  }

  .footer .navbar-nav {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
  }

  .footer .navbar-nav>li {
    margin-bottom: 10px;
  }

  .footer .navbar-nav>li a {
    width: 100%!important;
    margin: 0;
    min-height: 42px!important;
    height: 42px!important;
  }

  .footer .navbar {
    margin-top: 15px;
  }

  .copyright {
    padding: 0 15px;
  }

  .navbar-nav>li>a {
    min-height: 42px;
    height: 42px;
  }

  .header .block .uk-container span {
    font-size: 32px;
    line-height: 38px;
  }

  .header .block .uk-container {
    text-align: center;
    width: 480px;
  }

  .get-card-content .title-card.icon-first:after,
  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card.icon-second:after {
    content: none;
  }

  .get-card-content>.uk-grid>div:first-child {
    text-align: center;
  }

  .get-card-content>.uk-grid>div:last-child a {
    margin-left: auto!important;
    margin-right: auto!important;
    display: block;
    text-align: center;
  }

  .get-card-content>.uk-grid>div p {
    margin-left: 20%!important;
  }

  .login-container>a:last-child {
    margin-top: 10px;
  }

  .title.cabinet-tilte {
    font-size: 24px!important;
    line-height: 29px!important;
  }

  .uk-accordion .uk-accordion-title {
    padding: 18px 20px;
  }

  .uk-accordion li.uk-open .uk-accordion-content p {
    padding: 10px 16px;
  }

  .question .faq {
    margin-top: 60px;
  }

  .home .header .text-block {
    left: calc(50% - 480px/2 + 15px);
    right: calc(50% - 480px/2 + 15px);
  }

  .home .header .uk-slidenav-next {
    right: calc(50% - 480px/2 - 25px);
  }

  .home .header .uk-slidenav-previous {
    left: calc(50% - 480px/2 - 25px);
  }

  .footer .navbar-nav > li a {
    &:hover {
      -webkit-text-fill-color: #222222;
    }
  }
}

@media screen and (max-width:640px) {
  .header .block .uk-container,
  .uk-container {
    padding-left: 10px;
    padding-right: 10px;
  }

  .main-menu:not(.footer) .logo img {
    width: 194px;
  }

  .login-container>a:last-child {
    margin-top: 10px;
    margin-right: -2px;
  }

  .getcard .header {
    margin-top: 18px;
  }

  .getcard .header .uk-slider-items {
    min-height: 241px!important;
  }

  .get-card-content {
    margin-top: 72px;
  }

  .get-card-content .uk-grid:nth-of-type(1)>div:last-child p:first-child {
    margin-top: -7px;
  }

  .get-card-content .uk-grid:nth-of-type(2) {
    margin-top: 30px;
  }

  .get-card-content .uk-grid:nth-of-type(2)>div:last-child .title-card {
    margin-top: -3px;
  }

  .get-card-content .uk-grid:nth-of-type(3) {
    margin-top: 61px;
  }

  .get-card-content .uk-grid:nth-of-type(3)>div:last-child p:first-child {
    margin-top: -17px;
  }

  .getcard .footer {
    margin-top: 109px;
  }

  .header .block {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .header .text-block {
    left: calc(50% - 320px/2);
    right: calc(50% - 320px/2);
  }

  .button.primary,
  a.button.primary {
    height: 42px;
    line-height: 42px;
    min-height: 42px;
  }

  .button.bordered,
  .button.default,
  a.button.bordered,
  a.button.default {
    min-width: 100%;
  }

  .faq:after,
  .faq:before,
  .how-get:after,
  .how-get:before {
    content: none;
  }

  .login-container>a:last-child {
    margin-left: 0;
  }

  .home .header .text-block {
    left: calc(50% - 320px/2 + 15px);
    right: calc(50% - 320px/2 + 15px);
  }

  .home .header .uk-slidenav-next {
    right: calc(50% - 320px/2 - 45px);
  }

  .home .header .uk-slidenav-previous {
    left: calc(50% - 320px/2 - 45px);
  }
}