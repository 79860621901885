$max-row-width: 1008;
$grid-columns: 12;
$grid: (
  small: (
    from: 0,
    to: 977,
    gutter: 12,
    spacer: 8
  ),
  large: (
    from: 978,
    gutter: 30
  )
);
$body-font-size: 16px;
$font: "Arial", sans-serif;
$h-font: "Arial", sans-serif;

$t: transparent;

$primary-color: #01c767;
$alert-color: #b50000;

$default-transition: all 0.4s;