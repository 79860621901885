.stocks {
    .title {
        text-align: left;
        margin-top: 50px;
    }
    .uk-position-center-left {
        right: 0;
    }
    .uk-panel {
        padding-left: 40px;
        padding-right: 15px;
    }
    .remove-margin-top {
        margin-top: 0 !important;
    }
    .panel-default {
        p {
            font-weight: normal;
            font-size: 36px;
            line-height: 48px;
            color: black;
        }
        span {
            font-weight: normal;
            font-size: 16px;
            line-height: 22px;
            display: block;
            > span {
                display: inline-block;
                color: #FEB400;
            }
        }
        .button {
            margin-top: 40px;
            border: 1px solid #FEB400;
        }
        &.panel-white {
            p {
                color: white;
            }
            span {
                color: #E9E9E9;
            }
        }
        .gradient {
            min-width: 178px;
            text-transform: uppercase;
        }
    }
    .panel-primary {
        p {
            font-weight: normal;
            font-size: 48px;
            line-height: 64px;
            color: black;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            &.second-text {
                font-weight: bold;
                font-size: 28px !important;
                line-height: 38px;
                margin-bottom: 0;
                > span {
                    color: #FEB400;
                    display: inline-block;
                    padding-left: 0;
                    font-size: 28px !important;
                }
            }
            &.rounded {
                font-weight: bold;
                font-size: 44px;
                line-height: 50px;
                margin-bottom: 0;
                margin-top: 0;
                + .rounded {
                    margin-top: 0;
                    span {
                        &.round-text {
                            margin: 0;
                        }
                    }
                }
                               
                span {
                    &.round-text {
                        display: inline-block;
                        line-height: 87px;
                        width: 87px;
                        text-align: center;
                        position: relative;
                        padding-left: 0; 
                        border: 2px solid white;
                        border-radius: 50%;
                        background: rgba(24, 24, 24, 0.5);
                        margin-right: 10px;
                        font-size: 24px;
                        line-height: 84px;
                        > strong {
                            font-weight: inherit;
                            font-size: 32px;
                        }

                        &:after {
                            content: '';
                            display: block;
                            width: 77px;
                            height: 77px;
                            border: 2px dashed white;
                            border-radius: 50%;
                            position: absolute;
                            top: 3px;
                            left: 3px;
                        }
                    }
                    &.square-text {
                        font-weight: bold;
                        font-size: 24px;
                        line-height: 38px;
                        padding: 11px 14px;
                        border: 2px solid white;
                        border-radius: 8px;
                        background: rgba(0, 0, 0, 0.5);
                        display: inline-block;
                        margin-left: 10px;
                        > strong {
                            font-weight: inherit;
                            font-size: 32px;
                        }
                    }
                    &.same-text {
                        font-weight: bold;
                        font-size: 28px;
                        line-height: 38px;
                        padding-left: 0;
                        display: inline-block;
                    }
                }
            }
        }
        span {
            font-weight: bold;
            font-size: 32px;
            line-height: 38px;
            text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
            display: block;
            padding-left: 100px;
            + span {
                font-size: 72px;
                line-height: 86px;
                + span {
                    font-size: 24px;
                    line-height: 29px;
                    margin-top: 10px;
                    > span {
                        display: inline-block;
                        padding-left: 0;
                        font-size: 24px;
                        line-height: 29px;
                        color: #FEB400 !important;
                    }
                }
            }
        }
        .button {
            margin-top: 40px;
        }
        &.panel-white {
            p {
                color: white;
            }
            span {
                color: #E9E9E9;
            }
        }
        .gradient {
            min-width: 178px;
            text-transform: uppercase;
        }
    }
    
}
#slideshow {
    .uk-inline {
        > img {
            border-radius: 8px;
            &:nth-of-type(1) {
                display: inline-block;
            }
            &:nth-of-type(2) {
                display: none;
            }
            &:nth-of-type(3) {
                display: none;
            }
        }
    }
}
@media screen and (max-width: 1679px) {
    .stocks .panel-primary p.rounded span.same-text {
        font-size: 24px;
        line-height: 140%;
    }
    .stocks .panel-primary span {
        padding-left: 0;
    }
}
@media screen and (max-width: 1270px) {
    .stocks .panel-primary.panel-white p {
        font-size: 32px;
        line-height: 140%;
    }
    .stocks .panel-primary .button {
        margin-top: 20px;
    }
    #slideshow {
        .uk-inline {
            > img {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: inline-block;
                }
                &:nth-of-type(3) {
                    display: none;
                }
            }
        }
    }
}
@media screen and (max-width: 767px) {
    #slideshow {
        .uk-inline {
            display: block;
            margin: 0 auto;
            width: 300px;
            > img {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: none;
                }
                &:nth-of-type(3) {
                    display: inline-block;
                }
            }
        }
        .uk-slideshow-items {
            min-height: 380px !important;
        }
    }
    .stocks .uk-panel {
        padding-left: 33px;
        padding-right: 5px;
    }
    .stocks .panel-primary.panel-white p {
        font-size: 26px;
        line-height: 140%;
    }
    .stocks .panel-default p {
        font-size: 28px;
        line-height: 48px;
    }
    .stocks .panel-primary p.rounded span.round-text {
        width: 47px;
        line-height: 47px;
    }
    .stocks .panel-primary p.rounded span.round-text:after {
        width: 37px;
        height: 37px;
    }
    .stocks .panel-primary p.rounded span.round-text>strong, 
    .stocks .panel-primary p.rounded span.square-text>strong {
        font-size: 18px;
    }
    .stocks .panel-primary p.rounded span.round-text {
        font-size: 18px;
    }
    .stocks .panel-primary p.rounded span.square-text {
        font-size: 18px;
        padding: 3px 12px;
    }
    .stocks .panel-primary p.rounded span.same-text {
        font-size: 20px;
        margin: 10px 0;
        display: block;
    }
    .stocks .panel-primary .button {
        margin-top: 10px !important;
    }
    .stocks .uk-text-uppercase {
        margin-bottom: 50px;
    }
}
@media screen and (max-width: 639px) {
    #slideshow {
        .uk-inline {
            > img {
                &:nth-of-type(1) {
                    display: none;
                }
                &:nth-of-type(2) {
                    display: none;
                }
                &:nth-of-type(3) {
                    display: inline-block;
                }
            }
        }
       
    }
    .stocks {
        .uk-slideshow-items {
            li {
                padding-left: 0;
            }
        }
        .footer {
            margin-top: 50px;
        }
        .title {
            margin-bottom: 35px;
        }
    }
   
    
}