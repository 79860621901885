.title-second {
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    margin-top: 50px;
}
.form-settings {
    .uk-input {
        background: #FFFFFF;
        border: 1px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 6px;
        width: 377px;
        height: 42px;
        font-size: 14px;
        line-height: 42px;
        color: black;
        margin-bottom: 18px;
        &::placeholder {
            color: #ADADAD;
        }
        
    }
    .uk-radio {
        &[type=radio] {
            margin-left: 20px;
            margin-right: 11px;
        }
    }
    .uk-checkbox {
        margin-right: 18px;
        border: 1px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 6px;
    }
    p {
        font-size: 13px;
        line-height: 20px;
        color: #828282;
        a {
            text-decoration: underline;
            font-size: 13px;
            line-height: 20px;
            color: #828282;
        }
        span {
            color: #333333;
        }
    }
    label {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #333333;
    }
    span {
        font-weight: normal;
        font-size: 13px;
        line-height: 18px;
        color: #727D94;
    }
    &.success > * {
        opacity: 0;
    }

    &.success:after {
        content: "Спасибо, данные успешно отправлены!";
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: 500;
        position: absolute;
        background: #ffffff;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        border-radius: 19px;
        border: 1px solid #feaf00;
        transform: rotateY(0deg);
    }
}