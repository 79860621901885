@font-face {
    font-family: 'Lato';
    src: url('Lato-Regular.eot');
    src: url('Lato-Regular.eot?#iefix') format('embedded-opentype'),
        url('Lato-Regular.woff') format('woff'),
        url('Lato-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Lato bold';
    src: url('Lato-Bold.eot');
    src: url('Lato-Bold.eot?#iefix') format('embedded-opentype'),
        url('Lato-Bold.woff') format('woff'),
        url('Lato-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

