.cabinet-tilte {
    font-weight: bold !important;
    font-size: 36px !important;
    line-height: 38px !important;
    text-align-last: left;
}
.contact-form h4 + p {
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    color: #33383E;
}
.button-cabinet {
    margin-top: 42px;
}
.question .header .uk-slider-items {
    min-height: 380px !important;
}

.question .button-cabinet img {
    margin: 0 10px;
}

@media screen and (max-width: 1679px) {
    .question .header .uk-slider-items {
        min-height: 330px !important;
    }
}
@media screen and (max-width: 1270px) {
    .question .header .uk-slider-items {
        min-height: 280px !important;
    }
}
@media screen and (max-width: 767px) {
    .question .header .uk-slider-items {
        min-height: 240px !important;
    }
}