.contact-form {
    background: $bg-form;
    padding: 53px 0 30px 0;
    border-radius: 20px;
    position: relative;
    overflow: hidden;

    p {
        color: #828282;
        font-size: 13px;
        margin-top: 15px;

        a {
            color: #6E7DF8;
            text-decoration: none;
            border-bottom: 1px solid #6E7DF8;
            font-size: 13px;
        }
    }
    .button {
        text-transform: uppercase;
    }

    input[type=text],
    input[type=tel],
    input[type=email],
    textarea {
        background: white;
        font-family: $font;
        font-size: 14px;
        line-height: 18px;
        border-radius: 6px;
        display: inline-block;
        box-sizing: border-box;
        width: 100%;
        border: 0;
        outline: 0;
        padding: 11px 16px;
        
        &:not(:last-child) {
            margin-bottom: 10px;
        }
        &::placeholder {
            color: #ADADAD;
        }

        
    }
    textarea {
        height: 136px;
    }
    .align-flex {
        margin-top: 25px;
    }

    &:before {
        content: '';
        width: 278px;
        height: 427px;
        background: url('../images/triangle-bg.png') center no-repeat;
        position: absolute;
        left: 0;
        top: -5%;
        z-index: 1;
    }
    &:after {
        content: '';
        width: 278px;
        height: 427px;
        background: url('../images/triangle-bg.png') center no-repeat;
        position: absolute;
        right: 0;
        top: -5%;
        z-index: 1;
        transform: rotateY(180deg);
    }
    
    .uk-container-small {
        max-width: 575px !important;
    }
    h4 {
        text-transform: uppercase;
        margin-top: 0;
    }

    .underline-element {
        color: #6160E0;
        text-decoration: underline;
        font-size: 13px;
    }

    .policy label {
        font-family: 'Lato';
        color: #828282;
        font-size: 13px;
        line-height: 20px;
    }

    &.success:after {
        content: "Спасибо, данные успешно отправлены!";
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: 500;
        position: absolute;
        background: #ffffff;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        border-radius: 19px;
        border: 1px solid #feaf00;
        transform: rotateY(0deg);
    }
}