.main-menu {
    // max-width: $c-max-width !important;
    margin: 0 auto;  
    margin-top: 21px;
    .navbar-nav {
        margin-left: 61px;
    }
}
.navbar-nav {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;
    flex-direction: center;

    > li > a {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        box-sizing: border-box;
        min-height: 54px;
        height: 54px;
        padding: 8px 11px 8px 14px;
        margin: 0 3px;
        font-size: 16px;
        line-height: 19px;
        text-decoration: none;
        border: 1px solid #E4E4E4;
        border-radius: 8px;
        box-shadow: none;
        
        // transition: all .3s;

        &:hover {
            /* Fallback: Set a background color. */
            background-color: red;
            
            /* Create the gradient. */
            background-image: $gradient;
            
            /* Set the background size and repeat properties. */
            
            background-repeat: repeat;
            background-size: 100%;

            /* Use the text as a mask for the background. */
            /* This will show the gradient as a text color rather than element bg. */
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;
            
            &:active {
                background: $gradient;
                color: white;

                -webkit-background-clip: inherit;
                -webkit-text-fill-color: inherit; 
                -moz-background-clip: inherit;
                -moz-text-fill-color: inherit;
            }
        }
        &:active {
            background: $gradient;
            color: white;

            -webkit-background-clip: inherit;
                -webkit-text-fill-color: inherit; 
                -moz-background-clip: inherit;
                -moz-text-fill-color: inherit;

            &:hover {
                color: white !important;

                -webkit-background-clip: none;
                -webkit-text-fill-color: none; 
                -moz-background-clip: none;
                -moz-text-fill-color: none;
            }
        }

    }
    > li:first-child {
        > a {
            margin-left: 0;
        }
    }
    > li:last-child {
        > a {
            margin-right: 0;
        }
    }

    > li.active > a {
        color: white;
        background: $gradient;
        
        &:hover {
          color: white !important;
          background-color: red;
            
            /* Create the gradient. */
            background-image: $gradient;
            
            /* Set the background size and repeat properties. */
            
            background-repeat: repeat;
            background-size: 100%;

            /* Use the text as a mask for the background. */
            /* This will show the gradient as a text color rather than element bg. */
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent; 
            -moz-background-clip: text;
            -moz-text-fill-color: transparent;

            &:active {
                background: $gradient;
                color: white;

                -webkit-background-clip: inherit;
                -webkit-text-fill-color: inherit; 
                -moz-background-clip: inherit;
                -moz-text-fill-color: inherit;
            }
            
        }

        
        
    }
    
}

.uk-container:not(.footer) {

    .navbar-nav {

        > li:nth-of-type(1) > a {
            width: 120px;
        }
    
        > li:nth-of-type(2) > a {
            width: 132px;
        }
        
        > li:nth-of-type(3) > a {
          width: 172px;
        }

        > li:nth-of-type(4) > a {
            width: 77px;
        }
        > li:nth-of-type(5) > a {
            width: 136px;
        }

    }
}

.navbar-container {
    display: flex;
    position: relative;
    align-items: center;
}

.login-container {
    .button {
        // margin-left: 21px;
        height: 40px;
        line-height: 40px;
        min-height: 40px;
        margin-top: 9px;
        display: inline-flex;
    }
    > a:last-child {
        display: inline-block;
        margin-left: 50px;
        padding: 5px;
        border: 2px solid #ffbc01;
        background: transparent;
        border-radius: 6px;
        outline: 0;
        box-sizing: border-box;
        svg {
            > * {
                height: 2px;
            }

           
        }
        .uk-icon {
            color: #ffbc01;
            
        }

        &:hover {
            background: $border-btn-hover;
            border-color: white;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);

            svg {
                > * {
                    fill: white;
                }
            }

            &:acitve {
                background: $border-mob-active;
            }
        }
    }
}
