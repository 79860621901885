.button-program {
    .uk-slider-items {
        > li:first-child {
            svg {
                margin-right: 15px;
            }
        }
    }
}
.slide-bnt {
    margin-top: 85px;
    img {
        margin: 0 10px;
    }
}
.program .header .uk-slider-items {
    min-height: 380px !important;
}
.map-program {
    margin-top: 35px;
}
.swiper-container {
    width: 100%;
    max-height: 400px;
    padding-bottom: 50px;
    position: relative;
    .uk-panel {
        &.panel-scrollable {
            height: 270px;
        }
        .uk-list {
            border-bottom: 1px solid #D4D4D4;
            padding-bottom: 20px;
            margin-bottom: 20px;
            li {
                position: relative;
                box-sizing: border-box;
                display: flex;
                margin-right: 30px;
               
                svg {
                    margin-right: 20px;
                    width: 23px;
                    color: #B6D4FF;
     
                }
                strong {
                    font-weight: bold;
                    font-size: 16px;
                    line-height: 26px;
                }
                span {
                    font-weight: normal;
                    font-size: 16px;
                    line-height: 22px;
                }
                &.uk-divider {
                    border-bottom: 1px solid #D4D4D4;
                    padding-bottom: 20px;
                    margin-bottom: 10px;
                    &+li {
                        display: inherit;
                        strong {
                            font-weight: bold;
                            font-size: 16px;
                            line-height: 22px;
                            margin-bottom: 8px;
                            display: inline-block;
                        }
                        span {
                            font-weight: normal;
                            font-size: 14px;
                            line-height: 24px;
                            display: inline-block;
                        }
                    }
                }
                
 
            }
           li[data-map] {
               cursor: pointer;
           }
        }
    }
    &:after {
        content: '' ;
    }
}
.nav-block {
    height: 50px;
    margin-top: 20px;
    .down-slidenav {
        transform: rotate(90deg);
        z-index: 5;
        margin-left: -17px;
        margin-bottom: -4px;
    }
}
.panel-scrollable {
     .mCSB_container li  {
        counter-reset: rowNumber;
        .uk-panel  .uk-list {
            counter-increment: rowNumber;
            padding-left: 0;
            position: relative;
            padding-left: 40px;
            &:before {
                content: counter(rowNumber);
                position: absolute;
                left: 0;
                top: 8px;
                display: inline-block;
                width: 28px;
                height: 28px;
                border: 1px solid #FFB565;
                border-radius: 50%;
                font-family: $font;
                font-weight: normal;
                font-size: 14px;
                line-height: 17px;
                color: black;
                display: flex;
                justify-content: center;
                align-items: center;
            }
          }
    }
    
}
.ss-scroll {
    background: #D2D1FF;
    border-radius: 8px !important;
}
.swiper-slide {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    background: white;
    .uk-list {
        width: 100%;
    }
    // height: 100px !important;
}
.swiper-slide .panel {
    display: flex;
    align-items: flex-end;
    position: relative;
    .toggle-icon-check {
        position: absolute;
        cursor: pointer;
        right: 0;
        top: 1px;
        width: 23px;
        height: 23px;
        background: url(../images/icons/small-triangle.png) center no-repeat;
        border: 1px solid #6160E0;
        border-radius: 50%;
        transform: rotate(180deg);
        transition: all .3s;
        &.active {
            transform: rotate(0deg); 
        }
    }
}
.swiper-slide .panel .panel-heading {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
}
.swiper-slide .panel span {
    font-size: 14px;
}
.map-program .uk-panel-scrollable {
    height: 550px;
    border: 0;
}
.banner {
    margin-top: 100px;
    
    .wrapper-banner {
        position: relative;
        z-index: 0;
        display: flex;
        align-items: center;
        height: 200px;
        background: linear-gradient(236.18deg, rgba(255, 95, 1, 0.44) 0.48%, rgba(255, 192, 1, 0.49) 100%);
        border-radius: 8px;
        box-sizing: border-box;
        > .uk-flex {
            margin-left: 100px;
            margin-right: 100px;
            position: relative;
            z-index: 2;
            > div:last-child {
                display: flex;
                align-items: center;
                justify-content: flex-end;
            }
        }
        &:before {
            content: '';
            position: absolute;
            left: 15px;
            right: 15px;
            top: 15px;
            bottom: 15px;
            border: 1px solid white;
            border-radius: 8px;
            z-index: 1;
            background: transparent;
        }
        
    }
    .button {
        width: 178px;
        text-transform: uppercase;
        font-size: 14px;
    }
    p {
        font-size: 24px;
        line-height: 34px;
        text-transform: uppercase;
        margin-bottom: 0;
        margin-top: 0;
    }
    span {
        font-size: 14px;
        line-height: 22px;
        display: inline-block;
        margin-top: 15px;
    }
    
}
.swiper-pagination {
    left: 0;
    right: 0;
    .swiper-pagination-bullet {
        margin: 0 5px;
    }
}
@media screena and (max-width: 1679px) {
    .button-program li:first-child > a > span {
        font-size: 17px;
    }
    .button-program li:first-child > a span {
        font-size: 17px;
    }
}
@media screen and (max-width: 1270px) {
    .program .header .uk-slider-items {
        min-height: 280px !important;
    }
    .banner .wrapper-banner>.uk-flex {
        margin-left: 40px;
        margin-right: 40px;
        
    }
    .button-program li>a span {
        font-size: 16px;
    }
    .swiper-container {
        padding-bottom: 0;
        max-height: 100%;
        .uk-panel.panel-scrollable {
            height: auto;
        }
    }
    .nav-block {
        margin-top: 0;
    }
    .button-program li>a {
        height: 68px;
    }
    
}
@media screen and (max-width: 767px) {
    .program .header .uk-slider-items {
        min-height: 240px !important;
    }
    .banner .wrapper-banner {
        height: inherit;
        text-align: center;
        .button {
            width: 100%;
            margin-top: 30px;
        }
    }
    .banner .wrapper-banner > .uk-flex  {
        margin: 40px;
    }
    .banner p {
        font-size: 22px;
    }
}