.advantages {
    margin-top: 90px;
    .title-block {

        margin-bottom: 36px;

        img {
            flex-shrink: 0;
        }

        > div {
            display: flex;
            text-align: left;
            align-items: center;
            
            p {
                margin: 0;
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                padding-left: 18px;
            }

            + p {
                line-height: 24px;
                color: #51596A;
            }

         }       
       
    }
}