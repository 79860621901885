.uk-accordion { 
    li {
        &.uk-open {
            .uk-accordion-title {
                font-weight: bold;
                color: #33383E;
                border-color: #6160E0;
                background: white;
                &:after {
                    content: '';
                    transform: rotate(180deg);
                }
            }
            .uk-accordion-content {
                margin-top: 0;
                background: #F0F7FF;
                border-radius: 0 0 6px 6px;
                border-left: 1px solid #B2B1FF;
                border-right: 1px solid #B2B1FF;
                border-bottom: 1px solid #B2B1FF;
                p {
                    font-size: 16px;
                    line-height: 24px;
                    color: #646E83;
                    padding: 22px 25px;
                }
            }
        }
        &:nth-of-type(n+2) {
            margin-top: 10px;
        }
    }
    .uk-accordion-title {
        font-size: 16px;
        line-height: 19px;
        padding: 20px 25px;
        border: 1px solid #D5D5D5;
        box-sizing: border-box;
        border-radius: 6px;
        position: relative;
        &:after {
            content: '';
            width: 12px;
            height: 7px;
            background: url('../images/arrow-triangle.svg') center no-repeat;
            position: absolute;
            right: 20px;
            top: calc(50% - 7px / 2);
            transition: all .3s;
        }
        &:before {
            content: none;
        }
    }
}