@import "_my.uikit";
@import "_pages/import";




#main {
    overflow: hidden;
}

img {
    height: auto;
}
p , span, a, button, input {
    font-family: $font;
    font-size: $fsize;
    line-height: $lh;
    color: $tcolor;
    letter-spacing: 0.36px;
}
h3 {
    color: $tcolor;
    font-family: $font;
}
h4 {
    font-size: 24px;
    line-height: 29px;
    color: $tcolor;
    font-family: $font;
}

.uk-offcanvas-page {
    max-width: 1265px;
}

.margin-medium {
    margin-top: 35px;
}
.text-center {
    text-align: center;
}
.align-flex {
    display: flex;
    justify-content: center;
    .button {
        padding-left: 35px;
        padding-right: 35px;
    }
}
.align-flex-left {
    display: flex;
    justify-content: start;

}
.home {
    overflow-x: hidden;
}
.home .stocks {
    position: relative;
    &:before {
        content: '';
        background: url('../images/bt-triangle.svg') center no-repeat;
        width: 959px;
        height: 764px;
        position: absolute;
        top: -105%;
        right: 50%;
        margin-right: 23%;
        z-index: -1;
        transform: rotate(72deg);
    }

}
.home .faq {
    position: relative;
    &:after {
        content: '';
        background: url('../images/bt-triangle.svg') center no-repeat;
        width: 959px;
        height: 764px;
        position: absolute;
        top: -14%;
        left: 50%;
        margin-left: 22%;
        z-index: -1;
    }
}


.modal__container .modal__closer {
    top: -20px;
    right: -20px;
    &:hover {
        text-decoration: none;
    }
    &:before {
        content: 'x';
        color: #ffffff;
        font-size: 24px;

    }
}

form ul.errors {
    padding: 0;
    li {
        color: #FA6736;
    }

}

.uk-container {
    max-width: $c-max-width;
}

.stocks {
    // margin-top: 74px;
    .uk-slider-items {
        min-height: 480px;
        margin-top: 11px;
        .invert {
            margin-bottom: 70px;
        }
        .button {
            margin-bottom: -28px;
        }
    }
}
.contact {
    margin-top: 82px;
    .contact-form {
        .uk-container-small {
            margin-top: 21px;

            input {
                margin-bottom: 8px;
            }
            .uk-grid-margin {
                margin-top: 0;
            }
            .align-flex {
                .button {
                    padding-left: 45px;
                    padding-right: 45px;
                }
            }
        }

    }
}
.faq {
    margin-top: 79px;
    h3 {
        margin-bottom: 20px;
        + p {
            color: #727D94;
            margin-bottom: 50px;
        }
    }
    .accordion {
        margin-top: 54px;

    }
    .uk-accordion {
        margin-top: 58px;
    }
}


.bonus {
    margin-top: 95px;
    h3 + p {
        max-width: 750px;
        margin-left: auto;
        margin-right: auto;
        line-height: 24px;
        color: #51596A;
        margin-bottom: 50px;
    }
    h3 {
        margin-bottom: 30px;
    }
    .carousel-items {
        margin-top: 85px;
    }
}
.title {
    text-align: center;
    font-family: $font;
    font-size: 42px;
    line-height: 50px;
    font-weight: normal;
    padding: 0;
    margin-bottom: 60px;
    margin-top: 0;
}

.login-container .button:hover {
    background: linear-gradient(0deg,#ffb565,#ffb565),linear-gradient(55.55deg,#feb400 21.32%,#ff6001 93.91%);
    box-shadow: 0 5px 15px rgba(0,0,0,.15);
}


.logo {
    display: inline-block;
    position: relative;

    > span {
        display: inline-block;
        text-transform: uppercase;
        position: absolute;
        font-size: 12px;
        font-family: $fbold;
        bottom: -17px;
        left: 55px;
        letter-spacing: 0.45px;
    }

}


.footer {
    margin-top: 100px;
    .logo {
        img {
            width: 201px;
        }
        span {
            font-size: 10px;
        }
    }
     .navbar-nav {
         width: auto;
         justify-content: center;
         margin-left: 38px;
         li {
            a {
                padding: 8px 13px;
                margin: 0 5px;
            }
        }
        li:first-child a {
            margin-left: 0;
        }
        li:last-child a {
            margin-right: 0;
        }
    }
}

.header {
    margin-top: 20px;
    .block {
        position: absolute;
        left: 15px;
        right: 15px;
        top: 15px;
        bottom: 15px;
        .uk-container {
            width: 1170px;
            span {
                font-size: 52px;
                line-height: 62px;
                color: #FFFFFF;
                text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
            }
        }
    }
}
.breadcrumb {
    border-top: 1px solid #E4E4E4;
    margin-top: 23px;
    li {
        a {
            font-size: 13px;
            line-height: 22px;
            color: #727D94;
        }
        span {
            font-size: 13px;
            line-height: 22px;
            color: black !important;
        }
    }
    .uk-breadcrumb {
        margin-top: 13px;
        margin-bottom: -13px;
        > :nth-child(n+2):not(.uk-first-column)::before {
            content: '-';
            margin: 0 10px;
        }
    }
}
.copyright {
    height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid #F0F0F0;
    margin-top: 34px;

    p {
        font-size: 12px;
        color: #7B7675;
        text-align: center;
        margin-bottom: 12px;
    }
}

span {
    &[uk-icon=location] {
        color: #feca5e;
        svg {
            path {
            fill: #feca5e;
            }
            circle {
                fill: white;
            }
        }
    }
}
.uk-offcanvas-bar {
    background: white;
    padding: 15px;
    width: 320px;
    .uk-offcanvas-close {
        color: #ffbc01;
        padding: 6px;
        border: 2px solid #ffbc01;
        border-radius: 6px;
        background: transparent;

        svg {
            > * {
                height: 2px;
            }
        }

    }
    .button {
        color: white !important;
        border-radius: 35px;
        margin-top: 20px !important;
    }
}
.offcanvas-container {
    .logo {
        img {
            max-width: 194px;
            + span {
                font-size: 10px;
            }
        }
    }
    .navbar-nav {
        flex-direction: column;
        a {
            margin: 0;
            width: 100%;
            min-height: 42px;
            height: 42px;
            font-size: 14px;
        }
        li {
            margin-bottom: 10px;
            &:not(.active) {
                a {
                    color: #33383E;
                }
            }
        }

    }
}

.white-text{
    color: #ffffff;
}






