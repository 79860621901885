.wrapper {
    margin-top: 66px;
    p {
        font-size: 16px;
        line-height: 24px;
    }
}
.s-detail {
    .header {
        .uk-slider-items {
            min-height: 360px !important;
        }
        .block {
            .uk-container {
                > span:first-child {
                    width: 65%;
                    display: inline-block;
                }
            }
        }
        span.color-text {
            display: block;
            color: #FEB400 !important;
        }
    }
}

@media screen and (max-width: 1270px) {
    .s-detail {
        .header {
            .uk-slider-items {
                min-height: 300px !important;
                .uk-container {
                    > span:first-child {
                        width: 85%;
                        display: inline-block;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 639px) {
    .s-detail {
        .header {
            .uk-slider-items {
                .uk-container {
                    > span:first-child {
                        width: 90%;
                        display: inline-block;
                    }
                    
                }
                // .uk-cover {
                //     transform: translate(-63%,-50%);
                // }
            }
        }
    }
}