.cabinet-container {
    margin-top: 50px;
    .main-container {
        padding-left: 70px;
        .uk-overlay {
            p {
                font-weight: bold;
                font-size: 54px;
                line-height: 65px;
                margin-top: 15px;
            }
        }
    }
}
.cabinet-title {
    font-size: 36px;
    line-height: 43px;
}
.pagination-block {
    display: flex;
    justify-content: center;
}

.pagination-list {
    display: flex;
    padding: 0;

    li {
        margin: 0 15px;
    }
    .prev  a , .next a{
        border: 1px solid #6160E0;
        padding: 5px 10px;
        border-radius: 8px;
        color: #000;
        &:hover {
            color: #ffffff;
            text-decoration: none;
            background-color: #6160E0;
        }
    }
    a:hover {
        text-decoration: none;
        color: #6160E0;
    }

}

.list-status {
    margin-top: 40px;
    margin-bottom: 50px;
    li {
        margin-bottom: 20px;
        > span {
           width: 198px;
           display: inline-block;
        }
        > strong {
            font-size: 24px;
            line-height: 29px;
            color: #33383E;
            &.status-class1 {
                color: #68A5FF;
                .uk-icon {
                    background: #68A5FF;
                }
            }
            &.status-class2 {
                color: #009317;
                .uk-icon {
                    background: #009317;
                }
            }
            &.status-class3 {
                color: #F73F01;
                .uk-icon {
                    background: #F73F01;
                    width: 20px;
                    height: 20px;
                    position: relative;
                    top: 3px;
                    &:before {
                        content: 'x';
                        font-size: 14px;
                        position: absolute;
                        left: 6px;
                        top: 2px;
                        line-height: 17px;
                    }
                }
                svg {
                    display: none;
                }
            }
            .uk-icon {
                background: #009317;
                color: white;
                border-radius: 50%;
                padding: 2px;
            }
        }
    }
}
.uk-nav-default {
    background: #ECF6FF;
    border-radius: 8px;
    padding: 12px 19px;
    > li {
        &[hidden] {
            display: inherit !important;
        }
        &.uk-active {
            position: relative;
            a {
                font-family: $fbold;
                color: #F85101;
                padding-left: 16px;
                &:before {
                    content: '';
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.17476 0.539062C0.320321 0.539062 -0.140556 1.54136 0.415631 2.19L4.24087 6.65106C4.63995 7.11648 5.36005 7.11648 5.75913 6.65106L9.58437 2.19C10.1406 1.54136 9.67968 0.539062 8.82524 0.539062H1.17476Z' fill='%23F85101'/%3e%3c/svg%3e");
                    width: 10px;
                    height: 7px;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -5px;
                    transform: rotate(-90deg);
                }
            }
            .uk-icon {
                cursor: pointer;
                position: absolute;
                right: 0;
                top: 0;
                width: 50px;
                height: 100%;
                &:before {
                    content: '';
                    background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='7' viewBox='0 0 10 7' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M1.17476 0.539062C0.320321 0.539062 -0.140556 1.54136 0.415631 2.19L4.24087 6.65106C4.63995 7.11648 5.36005 7.11648 5.75913 6.65106L9.58437 2.19C10.1406 1.54136 9.67968 0.539062 8.82524 0.539062H1.17476Z' fill='%236160E0'/%3e%3c/svg%3e");
                    width: 10px;
                    height: 7px;
                    position: absolute;
                    left: calc(50% - 5px);
                    top: calc(50% - 3px);
                    
                }
            }
        }
        
        > a {
            font-size: 14px;
            line-height: 17px;
            color: #727D94;
            padding-top: 12px;
            padding-bottom: 12px;
            &:hover {
                color: #F85101;
            }
        }
        &:not(:last-child) {
            border-bottom: 1px solid #DEDEDE;
        }
    }
}
@media screen and (max-width: 1679px) {
    .cabinet-container {
        .uk-nav-default {
            margin-right: 15px;
        }
    }
}
@media screen and (max-width: 1270px) {
    .cabinet-container {
        .uk-nav-default {
            margin-right: 0;
        }
        .main-container {
            padding-left: 0;
            .uk-overlay {
                padding: 22px;
                > p {
                    margin-top: 15px;
                    font-size: 38px;
                    line-height: 46px;
                }
            }
        }
    }   
}
@media screen and (max-width: 767px) {
    .cabinet-container {
        .uk-nav-default {
            padding-top: 0;
            padding-bottom: 0;
            li {
                &:not(:first-child) {
                    // display:none;
                    
                }
                &:first-child {
                    border-bottom: 0;
                }
                a {
                    padding-bottom: 12px;
                    }
                
            }
        }
    }
    .uk-nav-default>li[hidden] {
        display: none !important;
    }
    .uk-nav-default>li:nth-of-type(2) {
        border-top: 1px solid #dedede;
    }
}
@media screen and (max-width: 639px) {
    
    .cabinet-title {
        font-size: 28px;
        line-height: 34px;
    }
    .list-status li>span {
        font-size: 12px;
        line-height: 14px;
        width: 160px;
    }
    .list-status li>strong {
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
    }
    .cabinet-container .main-container .uk-overlay>p {
        font-size: 28px;
        line-height: 34px;
        margin-top: 11px;
    }
    .cabinet-container .main-container .uk-overlay {
        padding: 14px;
    }
    .list-status {
        margin-top: 25px;
        margin-bottom: 35px;
       
    }
    .hidden-small {
        display: none;
    }
}
