$font: "Lato";
$fbold: "Lato bold"; 
$fsize: 16px;
$lh: 19px;
$tcolor: #33383E;
$lcolor: #33383E;
$c-max-width: 1170px;
$c-medium-width: 960px;
$gradient: linear-gradient(52.21deg, #FEB400 21.32%, #FF6001 93.91%);
$reverse-gradient: linear-gradient(275deg, #FEB400 21.32%, #FF6001 93.91%);
$bg-form: linear-gradient(249.31deg, rgba(255, 192, 1, 0.49) 0.48%, rgba(255, 95, 1, 0.44) 100%);
$login-btn: linear-gradient(55.55deg, #FEB400 21.32%, #FF6001 93.91%);
$login-btn-hover: linear-gradient(0deg, #FFB565, #FFB565), linear-gradient(55.55deg, #FEB400 21.32%, #FF6001 93.91%);
$default-btn: linear-gradient(36.45deg, #FEB400 21.32%, #FF6001 93.91%);
$border-btn-hover: linear-gradient(0deg, #FFB565, #FFB565);
$border-btn-active: linear-gradient(35.85deg, #FEB400 21.32%, #FF6001 93.91%);
$border-mob-active: linear-gradient(70.62deg, #FEB400 21.32%, #FF6001 93.91%);
$register-gradent: linear-gradient(20deg, #FEB400 21.32%, #FF6001 93.91%), #FFB565;



