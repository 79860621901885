.getcard {
    .header {
        margin-top: 24px;
    }
    
    .uk-slider-items {
        min-height: 330px !important;
    }
    .footer {
        margin-top: 148px;
    }
}

.get-card-content {
    margin-top: 94px;
    margin-bottom: 47px;
    
    .title-card {
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        color: black;
        + p {
            font-size: 16px;
            line-height: 24px;
        }
        &.icon-first {
            position: relative;
            &:before {
                content: '';
                width: 67px;
                height: 67px;
                background: url('../images/icons/004-pos.png') center no-repeat;
                position: absolute;
                left: -96px;
                top: -17px;
            }
            &:after {
                content: '';
                width: 409px;
                height: 209px;
                background: url('../images/icons/line-curve.png') center no-repeat;
                position: absolute;
                left: calc(-100% - -7px);
                top: 88px;

            }
        }
    }
    .uk-grid {
        > div {
            &:last-child {
                p, a {
                    margin-left: 96px;
                }
               
            }
        }
        &:nth-of-type(3) {
            margin-top: 50px;
            > div {
                &:last-child {
                    p {
                        padding-right: 20%;
                        &:first-child {
                            margin-top: 52px;
                        }   
                    }
                    .title-card {
                        &.icon-third {
                            position: relative;
                            &::before {
                                content: '';
                                width: 73px;
                                height: 74px;
                                background: url('../images/icons/003-business-card.png') center no-repeat;
                                position: absolute;
                                left: -96px;
                                top: -7px;
                            }
                        }
                    }
                }
            }
        }
        &:nth-of-type(2) {
            margin-top: 22px;
            > div {
                &:last-child {
                    p, a {
                        margin-left: calc(50% + 15px);
                    }
                    .title-card {
                        margin-top: 40px;
                        margin-bottom: 30px;
                        + p {
                            font-size: 14px;
                            line-height: 22px;
                            color: #727D94;
                        }
                        &.icon-second {
                            position: relative;
                            &:before {
                                content: '';
                                width: 66px;
                                height: 66px;
                                background: url('../images/icons/001-screen.png') center no-repeat;
                                position: absolute;
                                left: -96px;
                                top: 50px;
                            }
                            &:after {
                                content: '';
                                width: 409px;
                                height: 209px;
                                background: url('../images/icons/line-curve.png') center no-repeat;
                                transform: rotateY(180deg);
                                position: absolute;
                                left: -24%;
                                bottom: -311px;
                
                            }
                        }
                    }
                }
                img {
                    margin-left: 41px;
                }
            }
        }
        &:nth-of-type(1) {
            > div {
                &:last-child {
                    p {
                        padding-right: 20%;   
                        &:first-child {
                            margin-top: 122px;
                            
                        }
                    }
                }
            }
        }
    }
}