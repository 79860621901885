.form-register {
    width: 634px;
    text-align: center;
    input.uk-input {
        border-radius: 6px;
        border: 1px solid #D4D4D4;
        font-size: 14px;
        line-height: 18px;
        color: black;
        font-family: $font;
        &:focus {
            border-color: #7D7D7D;;
        }
        &.errors--active {
            border: 1px solid red;
        }
    }
    ul.errors {
        color: #FA6736;
        text-align: left;
        padding-left: 5px;
    }

    .container-form {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 19px;
        box-sizing: border-box;
        padding: 43px 24px;
        label {
            strong {
                font-family: $font;
                font-weight: bold;
                font-size: 13px;
                line-height: 18px;
                margin-right: 8px;
            }
            span {
                font-weight: normal;
                font-size: 13px;
                line-height: 18px;
                color:  #727D94;
                text-shadow: none;
                margin-left: 10px;
            }
        }
        .link-forget {
            font-size: 14px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #6160E0;
        }
        .title-form {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
        }
        .uk-inline {
            margin-bottom: 20px;
            img {
                border-radius: 20px;
            }
        }
    }
    .input-code {
        width: 157px;
        position: absolute;
        right: 22px;
        bottom: 17px;

        label {
            display: none;
        }
        input {
            background: #FFFFFF;
            border: 1px solid #D4D4D4;
            box-sizing: border-box;
            border-radius: 3px;
            color: black;
            font-size: 14px;
            line-height: 100%;
            display: inline-block;
            vertical-align: middle;
            align-items: center;
            padding: 0 4px;
            box-sizing: border-box;
            width: 76px;
            height: 28px;
            
            &:focus {
                border-color: black;
                outline: 0;
            }
        }
        .errors {
            position: absolute;
            background-color: #ffffff;
            top: -43px;
            border-radius: 6px;
            border: 1px solid #FA6736;
            padding: 3px;
            z-index: 10;
            &:before {
                content: 'x';
                position: absolute;
                color: #000000;
                right: 5px;
                text-align: center;
                width: 15px;
                height: 15px;
                cursor: pointer;
            }
        }
    }


    .policy {
        display: grid;
        grid-template-columns: auto auto;
        grid-column-gap: 15px;
        text-align: left;
        a {
            color: #6160E0;
            text-decoration: underline;
            span {
                color: #6160E0;
            }
        }
    }
}


.register {
    .text-block {
        align-items: center;
    }
    .header {
        .uk-slider-items {
            min-height: 809px !important;
        }
    }
    .button.default {
        min-width: 218px;
    }
   
}
.form-register p {
    color: #828282;
    font-size: 14px;
    margin-top: 20px;
}
.form-register p a {
    color: #6979F8;
    text-decoration: underline;
    font-size: 14px;
}
.form-register .text-center {
    text-align: left;
    label {
        float: left;
        margin-top: 2px;
        margin-right: 15px;
    }
}

@media screen and (max-width: 1249px) {
    .register {
        .header {
            .uk-slider-items {
                min-height: 660px !important;
            }
        }
    }
    .form-register .container-form {
        padding: 22px 16px;
    }
}
@media screen and (max-width: 767px) {
    .register {
        .header {
            .uk-slider-items {
                min-height: 670px !important;
            }
        }
        
    }

    .input-code {
        top: 15px;
        .errors {
            top: -5553px;
        }

    }


    .form-register .radio-list {
        margin-bottom: 20px;

        li:first-child {
            &:before {
                left: -40px;
            }
        }
        & + .errors {
            position: absolute;
            margin-top: 25px;
            bottom: 0;

        }
    }
   
    .form-register .container-form label strong {
        margin-right: 4px;
    }


}
@media screen and (max-width: 639px) {
    .register {
        .header {
            .uk-slider-items {
                min-height: 892px !important;
            }
        }
        .button.default {
            width: 100%;
        }
    }
    .input-code {
        top: 25px;

        .errors:after {
            content: '';
            display: block;
            position: absolute;
            bottom: -10px;
            left: 50%;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 10px 10px 0 10px;
            border-color: #ffffff transparent transparent transparent;
        }
        .errors:before {
            display: none;
        }
    }
    .form-register .container-form {
        padding: 22px 11px;
    }
    .form-register .input-code input {
        width: 55px;
        height: 18px;
        font-size: 12px;
        line-height: 18px;
    }
    .form-register {
        width: 100%;
        .input-code .errors {
                top: -145px;
                border:none;
                box-shadow: 6px 7px 8px 5px rgba(0,0,0,0.2);
        }
    }
    .form-register .input-code {
        width: 113px;
        right: 18px;
        bottom: 14px;
    }
}