.form-login {
    max-width: 376px;
    text-align: center;
    input.uk-input {
        border-radius: 6px;
        margin-bottom: 19px;
        border: 1px solid #D4D4D4;
        font-size: 14px;
        line-height: 18px;
        color: black;
        font-family: $font;
        &:focus {
            border-color: #7D7D7D;;
        }
    }
    .container-form {
        background: rgba(255, 255, 255, 0.8);
        border-radius: 19px;
        box-sizing: border-box;
        padding: 43px 24px;
        .link-forget {
            font-size: 14px;
            line-height: 18px;
            text-decoration-line: underline;
            color: #6160E0;
        }
        .title-form {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
        }
    }
    .errors {
        padding: 0;
        color: #FA6736;
        text-align: left;
    }
}
.form-card-block {
    &.success > * {
        opacity: 0;
    }

    &.success:after {
        content: "Спасибо, данные успешно отправлены!";
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 28px;
        font-weight: 500;
        position: absolute;
        background: #ffffff;
        width: calc(100% - 2px);
        height: calc(100% - 2px);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        border-radius: 19px;
        border: 1px solid #feaf00;
        transform: rotateY(0deg);
    }
}
.login {
    .text-block {
        align-items: center;
    }
    .header {
        .uk-slider-items {
            min-height: 660px !important;
        }
    }
    .button.default {
        width: 100%;
    }
}

@media screen and (max-width: 1679px) {
    .header .text-block .form-login {
        & p {
            color: #33383E;
            text-shadow: none;
        }
    }
}


@media screen and (max-width: 1249px) {
    .login {
        .header {
            .uk-slider-items {
                min-height: 590px !important;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    .login {
        .header {
            .uk-slider-items {
                min-height: 520px !important;
            }
        }
    }
}
@media screen and (max-width: 639px) {
    .header .text-block .form-login {
        max-width: 300px;
    }
}