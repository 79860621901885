.card-transaction {
    margin-top: 40px;
    .uk-overlay {
        padding: 15px;
        p {
            font-weight: bold;
            font-size: 42px;
            line-height: 50px;
            margin-top: 55px;
        }
    }
}
.transaction {
    .uk-list {
        span {
            font-size: 13px;
            line-height: 16px;
            color: #51596A;
        }
        .name-client {
            font-size: 18px;
            line-height: 22px;
            font-weight: normal;
            color: #33383E;
        }
        .success {
            font-weight: normal;
            font-size: 18px;
            line-height: 22px;
            .uk-icon {
                color: white;
                border-radius: 50%;
                padding: 1px;
                width: 17px;
                height: 17px;
                text-align: center;
                box-sizing: border-box;
                svg {
                    vertical-align: text-top;
                }
            }
        }
        .status-class1 {
            color: #68A5FF;
            .uk-icon {
                background: #68A5FF;
            }
        }
        .status-class2 {
            color: #009317;
            .uk-icon {
                background: #009317;
            }
        }
        .status-class3 {
            color: #F73F01;
            .uk-icon {
                background: #F73F01;
                &:before {
                    content: 'x';
                    font-size: 14px;
                    margin-left: 1px;
                    line-height: 15px;
                }
            }
            svg {
                display: none;
            }
        }
        .total-points {
            font-weight: bold;
            font-size: 24px;
            line-height: 29px;
            color: #33383E;
        }
    }
    .uk-form-width-small {
        width: 190px;
        background: #FFFFFF;
        border: 1px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
    }
    .small-title {
        display: inline-block;
        padding: 0 18px;
    }
}
.list-time {
    li {
        padding: 15px 18px;
        border: 1px solid #D4D4D4;
        box-sizing: border-box;
        border-radius: 6px;
        font-size: 14px;
        line-height: 17px;
        color: #51596A;

        &:not(:last-child) {
            margin-bottom: 10px;
        }
        > div:first-child {
            width: 25%;
        }
        > div:nth-of-type(2) {
            font-size: 16px;
            line-height: 19px;
            font-weight: 500;
            color: #33383E;
            width: 50%;
        }
        > div:last-child {
            font-size: 14px;
            line-height: 17px;
            width: 25%;
            strong {
                font-weight: bold;
                font-size: 16px;
                line-height: 19px;
                color: #000000;
            }
        }
    }
}
.time-data {
    section {
        display: inline-block;
        &:first-child {
            line-height: 38px;
        }
        input {
            margin-right: 15px;
        }
    }
    i {
        font-style: inherit;
        // padding: 0 15px;
        display: inline-flex;
    }
}
.time-data {
    > div {
        &:first-child {
            > section {
                &:last-child {
                    // margin: 0 15px;
                }
            }
        }
    }
    input {
        margin-left: 15px;
    }
}
@media screen and (max-width: 1270px) {
    .time-data,
    .small-title {
        font-size: 12px;
        line-height: 14px;
    }
    .list-time {
        li {
            > div {
                &:first-child {
                    font-size: 12px;
                    line-height: 14px;
                }
                &:nth-of-type(2) {
                    
                    font-size: 14px;
                    line-height: 17px;
                }
                &:last-child {
                    
                }
            }
        }
    }
    .transaction .uk-list .name-client,
    .transaction .uk-list .success {
        font-size: 14px;
    }
    .transaction .uk-list .total-points {
        font-size: 20px;
        line-height: 20px;
    }
}
@media screen and (max-width: 767px) {
    .card-transaction {
        text-align: center;
    }
    .list-items {
        li {
            width: 49%;
            &:first-child {
                float: left;
            }
            &:last-child {
                float: right;
                margin: 0;
            }
        }
    }
    .transaction .uk-list span {
        line-height: 29px;
    }
    
    
}
@media screen and (max-width: 639px) {
    .time-data {
        
        > div {
            &:first-child {
                width: inherit;
                margin-bottom: 10px;
            }
            &:last-child {
                width: inherit;
                text-align: right;
            }
        }
    }
    .list-time {
        li {
            flex-wrap: wrap;
            > div {
                &:first-child {
                    width: 33%;
                }
                &:last-child {
                    width: 50%;
                    margin-top: 15px;
                    margin-left: 33%;
                }
            }
        }
    }
    .transaction .uk-form-width-small {
        width: 130px;
    } 
}
@media screen and (max-width: 500px) {
    .time-data {
        flex-direction: column;
        section {
            &:first-child {
                float: left;
            }
            &:last-child {
                float: right;
                margin-right: 0 !important;
            }
            input {
                margin-right: 0;
            }
        }
    }
}