
.how-get {
    margin-top: 39px;

    .get-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        p {
            text-align: center;
            margin-bottom: 0;

            + p {
                padding: 0 40px;
            }
        }

        .title-card {
                font-family: $fbold;
                font-weight: bold;
                font-size: 18px;
                line-height: 140%;
                padding-left: 18px;
                margin-top: 40px;
        }
    }

    .text-align {
        text-align: center;
        margin-top: 12px;

    }

}