
.button,
a.button,
button:not(.uk-offcanvas-close) {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 17px;
    color: white;
    border-radius: 20px;
    min-width: 78px;
    min-height: 46px;
    height: 46px;
    line-height: 46px;
    padding: 0 12px;
    text-decoration: none;
    transition: all .3s;
    border: 0;

    &.form-button {
        background: #FFFFFF;
        border: 1px solid #feaf00;
        color: #33383E;
        height: 42px;
        min-height: 42px;
        line-height: 42px;
        width: 320px;

        &:hover {
            background: #FFB565;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
            color: white;
        }
        &:active {
            color: white;
            background: $register-gradent;
        }
    }

    &.primary {
        text-transform: uppercase;
        background: #6160E0;
        min-width: 200px;
        height: 52px;
        min-height: 52px;
        line-height: 52px;
        &:hover {
            border: 1px solid #FFB565;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        }
    }
    &.default {
        text-transform: uppercase;
        background: $default-btn;
        width: 177px;
        z-index: 1;
        position: relative;
        &:hover {
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        }
    }

    &.gradient {
        background: $gradient;
        

    }
    &.bordered {
        border: 1px solid #feaf00;
        background-color: #FEB400;
        color: #33383E;
        text-transform: uppercase;
        min-width: 177px;
        height: 45px;
        line-height: 45px;
        min-height: 45px;
        //background: transparent;
        // transition: all .3s;

        &:hover {
            background: $border-btn-hover;
            color: white;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
        }

        &:active {
            background: $border-btn-active;
        }
    }
    &.button-white {
        background: white;
        border-radius: 55px;
        text-transform: uppercase;
        color: #33383E;
        min-width: 178px;
        &:hover {
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.1);
        }
    }
}
.login-container {
    .button {
        width: 78px;
        height: 40px;
        line-height: 40;
        min-height: 40px;
        border-radius: 20px;
        background: $login-btn;

        &:hover {
            background: $login-btn-hover;
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.15);
        }
    }
}
.uk-close:hover {
    color: #ff9501 !important;
}

.button-cabinet {
    li {
        > a {
            display: flex;
            align-items: center;
            text-decoration: none;
            border: 1px solid #feaf00;
            width: 100%;
            min-height: 100px;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            box-sizing: border-box;
            padding: 25px;
            border-radius: 8px;
            transition: all .3s;
            > svg {
                margin-right: 25px;
                min-width: 42px;
            }
            &:hover {
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
                color: #353535;
            }
            &:active {
                border-color:#FF6401;
                color: white;
                background: #FFB565;
                > svg {
                    .st0, .st1, .st2, .st3, .st4, .st5, .st6 {
                        fill: white;
                    }
                }
            }
        }
        &.active {
            > a {
                background: $gradient;
                color: white;
                > svg {
                    .st0, .st1, .st2, .st3, .st4, .st5, .st6 {
                        fill: white;
                    }
                }
                &:hover {
                    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
                }
                &:active {
                    border-color:#FF6401;
                    color: white;
                    background: #FFB565;
                    > svg {
                        .st0, .st1, .st2, .st3, .st4, .st5, .st6 {
                            fill: white;
                        }
                    }
                }

            }
        }
    }
}
.button-program {
    .uk-slider {
        margin-left: 30px;
        margin-right: 30px;
    }
    li {
        > a {
            display: flex;
            align-items: center;
            text-decoration: none;
            border: 1px solid #feaf00;
            width: 100%;
            height: 80px;
            font-weight: bold;
            font-size: 18px;
            line-height: 22px;
            box-sizing: border-box;
            padding: 25px;
            border-radius: 8px;
            transition: all .3s;
            span {
                font-size: 18px;
                color: #353535;
            }
            > svg {
                margin-right: 25px;
                min-width: 42px;
            }
            &:hover {
                box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
                color: #353535;
            }
            &:active {
                border-color:#FF6401;
                color: white;
                background: #FFB565;
                span {
                    color: white;
                }
                > svg {
                    .st0, .st1, .st2, .st3, .st4, .st5, .st6 {
                        fill: white;
                    }
                }
            }
        }
        &.active {
            > a {
                background: #FFB565;
                color: white;
                span {
                    color: white;
                }
                > svg {
                    .st0, .st1, .st2, .st3, .st4, .st5, .st6 {
                        fill: white;
                    }
                }
                &:hover {
                    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.08);
                }
                &:active {
                    border-color:#FF6401;
                    color: white;
                    background: #FFB565;
                    > svg {
                        .st0, .st1, .st2, .st3, .st4, .st5, .st6 {
                            fill: white;
                        }
                    }
                }

            }
        }
    }
}
.uk-checkbox, .uk-radio {
    width: 22px;
    height: 22px;
}
.uk-radio {
    border-color: #FFB565;
}

.uk-checkbox:indeterminate, 
.uk-radio:checked {
    background-color: #FFB565 !important;
    background-size: cover;
    background-image: url("data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Ccircle%20fill%3D%22%23fff%22%20cx%3D%228%22%20cy%3D%228%22%20r%3D%225%22%20%2F%3E%0A%3C%2Fsvg%3E");
    background-position: center -0.5px;
}
.uk-checkbox:checked {
    background-color: #FFB565 !important;
    border-radius: 6px;
}

@media screen and (max-width: 1679px) {
    .button-program {
        li {
            > a {
                justify-content: center;
            }
        }
    }
}

@media screen and (max-width: 639px) {
    .button.form-button {
        width: 238px !important;
    }
    
}