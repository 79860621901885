.text-block {
  position: absolute;
  padding: 25px 55px;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  &__wrap {
    background: rgba(0, 0, 0, 0.3);
    padding: 25px;
    width: 58%;
    @media screen and (max-width: 1679px) {
      width: 58%;
    }
    @media screen and (max-width: 1270px) {
      width: 80%;
    }
    @media screen and (max-width: 900px) {
      width: 100%;
    }
  }
  span {
    font-family: $font;
    font-size: 36px;
    line-height: 54px;
    color: white;
    text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    max-width: 60%;

    @media screen and (max-width: 900px) {
      font-size: 28px;
      line-height: 34px;
    }
  }
  .text-block__paragraph {
    margin-top: 20px;
    p {
      font-size: 17px;
      color: #fff;
      line-height: 22px;
      text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    }
    
  }
}
.uk-slidenav-container .uk-icon svg {
  stroke: black !important;
  fill: black !important;
}
.header .uk-slidenav svg #bg {
  fill: white !important;
}
.home .header {
  .uk-slider-items {
    min-height: 460px !important;
  }
  .text-block {
    position: absolute;
    left: calc(50% - #{$c-max-width} / 2 + 35px);
    right: calc(50% - #{$c-max-width} / 2 + 35px);
    top: 0;
    bottom: 0;

    .primary {
      margin-top: 35px;
    }
    
  }
  .uk-slidenav-previous {
    left: calc(50% - #{$c-max-width} / 2 - 45px);
  }
  .uk-slidenav-next {
    right: calc(50% - #{$c-max-width} / 2 - 45px);
  }

  .home .uk-slidenav {
    &.uk-slidenav-previous {
      svg {
        #bg {
          fill: white !important;
        }
        &:hover {
          #bg {
            fill: #6160E0 !important;
          }
          .cls-2 {
            fill: white !important;
           
          }
          .cls-2#arround {
            fill: #6160E0 !important;
          }
        }
      }
      
    }
  }
 
}

.home .uk-slidenav-previous {
  left: -45px;
}
.home .uk-slidenav-next {
  right: -45px;
}

.home .uk-slidenav {
  svg:last-child {
    display: none;
  }
  &.uk-slidenav-next {
    svg {
      transform: rotate(180deg);
      #bg {
        fill: #6160E0 !important;
      }
      .cls-2 {
        fill: white !important;
       
      }
      .cls-2#arround {
        fill: #6160E0 !important;
      }
    }
  }
  svg:hover {
    #bg {
      fill: #6160E0;
    }
    .cls-2 {
      fill: white;
     
    }
    .cls-2#arround {
      fill: #6160E0;
    }
  }
  svg {
    transition: all .3s;
    #bg {
      fill: transparent;
    }
  }
}

.stocks {
  .uk-cover-container {
    border-radius: 20px;
    margin: 0 10px;
  }
  .text-block {
    padding: 0 100px;
    p {
      font-size: 18px;
      line-height: 22px;
      color: #7D7D7D;
    }
    span.invert {
      font-weight: bold;
      font-size: 38px;
      line-height: 46px;
      color: black;
      text-shadow: none;
      margin-bottom: 51px;
    }
  }
}
.slider-other {
  .slidenav-container {
    margin-top: 56px;
    > a:first-child {
      margin-right: 13px;
    }
    > a:last-child {
      margin-left: 13px;
    }
  }
  li {
    div:first-child {
      display: flex;
      align-items: center;
      min-height: 112px;
    }
    div:last-child {
      p {
        &:first-child {
          font-weight: bold;
          font-size: 18px;
          line-height: 22px;
          margin-bottom: 0;
          margin-top: 13px;
          + p {
            font-size: 13px;
            line-height: 22px;
            color: #727D94;
            margin-top: 5px;
            margin-bottom: 15px;
            + p {
              margin: 15px 0;
            }
          }
        }
      }
      a {
        font-size: 14px;
        line-height: 22px;
        color: #6160E0;
        display: inline-block;
        border-bottom: 1px solid #6160E0;
        text-decoration: none;
        margin-top: 6px;
      }
    }
  }
}
.uk-dotnav {
  > .uk-active {
      >* {
          background: #6160E0;
          width: 8px;
          height: 8px;
      }
  }
  >* {
      >* {
          background: white;
          width: 8px;
          height: 8px;
      }
  }
}
.bonus {
    .slidenav-container {
      span {
        display: inline-block;
        width: 30px;
        height: 34px;
        padding: 5px 0;
        line-height: 34px;
        text-align: center;
        page-break-after: 5px;
        font-family: $font;
        font-size: 14px;
        color: black;
        &.active {
          font-weight: bold;
        }
        &.liner {
          width: 43px;
          position: relative;
          &:before {
            content: '';
            width: 43px;
            height: 1px;
            background: #D8D8D8;
            position: absolute;
            left: 0;
            top: calc(34px / 2 + 2px);
          }
        }
      }
  }
}


.member-block {
  display: flex;
  flex-direction: column;
  &__name {
    font-weight: bold;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 0;
    margin-top: 13px;
  }
  &__descr {
    font-size: 13px;
    line-height: 22px;
    color: #727D94;
    margin-top: 5px;
    margin-bottom: 15px;
  }
  div a {
    font-size: 14px;
    line-height: 22px;
    color: #6160E0;
    display: inline-block;
    border-bottom: 1px solid #6160E0;
    text-decoration: none;
    margin-top: 6px;
  }

}
.liner {
  width: 43px;
  display: inline-block;
  height: 1px;
  background: #33383E;
  vertical-align: middle;
}
.swiper-wrapper-nav {
  margin-top: 56px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper-pagination.swiper-pagination-fraction {
  position: relative;
  bottom: auto;
  width: auto;
  margin: 0 30px;
}

.swiper-wrapper-nav  {

  .swiper-button-prev,.swiper-button-next {
 
    position: static;
    display: none;
    height: auto;
    width: auto;
    margin-top: 0;
    background: #6160E0;
    color: #fff;
    border-radius: 5px;
    padding: 7px 20px;
  }
  .swiper-button-prev::after,.swiper-button-next::after {
    font-size: 16px;
  }
}
.active.swiper-pagination-current {
  font-weight: bold;
}

@media screen and (max-width: 768px) {
  .swiper-wrapper-nav {
    .swiper-button-prev,.swiper-button-next {
      display: inline-block;
    }

  }
  .member-block div {
    text-align: center;
  }
}